import React, { Component } from 'react';
import { StandartColors } from "../../utils/enums/StandartColors";
import './TabComponent.css';
// import CorrelationViewTabComponent from './ProcessTabComponent/AutomatedCorrelationPages/CorrelationViewTabComponent';
import TableViewTabComponent from './TableViewTabComponent/TableViewTabComponent';
import WellViewTabComponent from './WellViewTabComponent/WellViewTabComponent';
import MapViewTabComponent from './MapViewTabComponent/MapViewTabComponent';
import WellHeadsComponent from './ProcessTabComponent/WellHeadsComponent';
import WellLogsComponent from './ProcessTabComponent/WellLogsComponent';
import { EventNames } from '../../utils/enums/EventNames';
import WellMarkersComponent from './ProcessTabComponent/WellMarkersComponent';
import WellMarkersExportComponent from './ProcessTabComponent/WellMarkersExportComponent';
import WellPathsComponent from './ProcessTabComponent/WellPathsComponent';
import WellMarkersRemoveComponent from './ProcessTabComponent/WellMarkersRemoveComponent';
import { ActionsTabTypes } from '../../utils/enums/ActionsTabTypes';
import { ViewTypeTabName } from '../../utils/enums/ViewTypeTabName';
import AutomatedCorrelationComponent from './ProcessTabComponent/AutomatedCorrelationPages/AutomatedCorrelationComponent';
import CorrelationsViewTabComponent from './CorrelationsViewTabComponent/CorrelationsViewTabComponent';
import CorrelationsRemoveComponent from './ProcessTabComponent/CorrelationsRemoveComponent';
import WellRemoveComponent from './ProcessTabComponent/WellRemoveComponent';


/**
 * Change View Tab to Correlation View Tab.
 */
export function setCorrelationViewTab(){
  const customEvent = new CustomEvent(EventNames.changeTabEvent, { detail: {
    tabName: ViewTypeTabName.CORRELATION_VIEW,
    extraInfo: ""
  } 
  });
  document.dispatchEvent(customEvent);
}

interface ViewTabsComponentState {
  activeTab: ViewTypeTabName.MAP_VIEW | ViewTypeTabName.WELL_VIEW | ViewTypeTabName.TABLE_VIEW | ViewTypeTabName.CORRELATION_VIEW | ViewTypeTabName.PROCESS;
  extraInfo: ActionsTabTypes.WELLHEADS | ActionsTabTypes.WELLLOGS  | ActionsTabTypes.WELLPATHS | ActionsTabTypes.WELLMARKERS | ActionsTabTypes.WELLMARKERSEXPORT | ActionsTabTypes.WELLMARKERSREMOVE |ActionsTabTypes.WELLSETS | ActionsTabTypes.TIMEMARKERS | ActionsTabTypes.CULTURALDATA | ActionsTabTypes.AUTOMATED_CORRELATION | ActionsTabTypes.DELETECORRELATIONS | ActionsTabTypes.WELLSREMOVE;
}


class ViewTabsComponent extends Component<{}, ViewTabsComponentState> {

  changeTabEventName:string = EventNames.changeTabEvent;

  constructor(props: any) {
    super(props);    

    this.state = {
      activeTab: ViewTypeTabName.WELL_VIEW, // Set the default active tab
      extraInfo: ActionsTabTypes.WELLHEADS
    };
  }

  componentDidMount() {  
    document.addEventListener(this.changeTabEventName, this.handleTabChangeEventListner);
  }

  componentWillUnmount() {
    document.removeEventListener(this.changeTabEventName, this.handleTabChangeEventListner);
  }

  handleTabChangeEventListner = (event: any) => {
    if (event.type !== this.changeTabEventName){
      return;
    }      

    var detail = event.detail;   
    var tabName = detail.tabName;
    var extraInfo = detail.extraInfo;
    this.handleProcessTabChangeEventListner(tabName, extraInfo)    
    
  };

  handleProcessTabChangeEventListner = (tabName: any, extraInfo: any) =>{
    this.handleTabClick(tabName, extraInfo);
  }

  handleTabClick = (tabId: any, extraInfo:any) => {
    const customEvent = new CustomEvent(EventNames.changeTabEvent2, { detail: {tabId} 
    });    
    document.dispatchEvent(customEvent);
    this.setState({ activeTab: tabId, extraInfo});
  };

  getProcessComponentView = () =>{    
    if(this.state.extraInfo == ActionsTabTypes.WELLHEADS){
      return <WellHeadsComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLLOGS){
      return <WellLogsComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLMARKERSEXPORT){
      return <WellMarkersExportComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLMARKERSREMOVE){
      return <WellMarkersRemoveComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLSREMOVE){
      return <WellRemoveComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLMARKERS){
      return <WellMarkersComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.WELLPATHS){
      return <WellPathsComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.AUTOMATED_CORRELATION){
      return <AutomatedCorrelationComponent />
    }
    else if(this.state.extraInfo == ActionsTabTypes.DELETECORRELATIONS){
      return <CorrelationsRemoveComponent />
    }
    else{
      return <>{this.state.extraInfo}</>
    }
  }

  render() {
    const { activeTab } = this.state;


    return (
      <section id="main-tabs">
        <nav>
          <div className="nav nav-tabs nav-fill" id="nav-tab-map-view" role="tablist">
            {/* <a
              className={`nav-item nav-link ${activeTab === 'Map View' ? 'active' : ''}`}
              id="map-view-tab"
              onClick={() => this.handleTabClick('Map View', this.state.extraInfo)}
              aria-controls="Map View"
              aria-selected={activeTab === ViewTypeTabName.MAP_VIEW}
            >
              Map View
            </a> */}
            <a
              className={`nav-item nav-link ${activeTab === ViewTypeTabName.WELL_VIEW ? 'active' : ''}`}
              id="well-view-tab"
              onClick={() => this.handleTabClick(ViewTypeTabName.WELL_VIEW, this.state.extraInfo)}
              aria-controls="Well View"
              aria-selected={activeTab === ViewTypeTabName.WELL_VIEW}
            >
              Well View
            </a>
            {/* <a
              className={`nav-item nav-link ${activeTab === 'Table View' ? 'active' : ''}`}
              id="table-view-tab"
              onClick={() => this.handleTabClick(ViewTypeTabName.TABLE_VIEW, this.state.extraInfo)}
              aria-controls="Table View"
              aria-selected={activeTab === ViewTypeTabName.TABLE_VIEW}
            >
              Table View
            </a> */}
            <a
              className={`nav-item nav-link ${activeTab === 'Correlation View' ? 'active' : ''}`}
              id="correlation-view-tab"
              onClick={() => this.handleTabClick('Correlation View', this.state.extraInfo)}
              aria-controls="Correlation View"
              aria-selected={activeTab === ViewTypeTabName.CORRELATION_VIEW}
            >
              Correlation View
            </a>
            <a
              className={`nav-item nav-link ${activeTab === ViewTypeTabName.PROCESS ? 'active' : ''}`}
              id="process-tab"
              onClick={() => this.handleTabClick(ViewTypeTabName.PROCESS, this.state.extraInfo)}
              aria-controls="Process"
              aria-selected={activeTab === ViewTypeTabName.PROCESS}
            >
              Process
            </a>
          </div>
        </nav>
        <div className="tab-content" id="nav-tab-map-view-component">
          <div
            className={`tab-pane fade show ${activeTab === ViewTypeTabName.MAP_VIEW ? 'active' : ''}`}
            id="Map-View"
            role="tabpanel"                
          >
            <MapViewTabComponent />
          </div>
          <div
            className={`tab-pane fade ${activeTab === ViewTypeTabName.WELL_VIEW ? 'show active' : ''}`}
            id="Well-View"
            role="tabpanel"                
          >
            <WellViewTabComponent />
          </div>
          <div
            className={`tab-pane fade ${activeTab === ViewTypeTabName.TABLE_VIEW ? 'show active' : ''}`}
            id="Table-View"
            role="tabpanel"                
          >
            <TableViewTabComponent />
          </div>
          <div
            className={`tab-pane fade ${activeTab === ViewTypeTabName.CORRELATION_VIEW ? 'show active' : ''}`}
            id="Correlation-View"
            role="tabpanel"                
          >
            <CorrelationsViewTabComponent />
          </div>
          <div
            className={`tab-pane fade ${activeTab === ViewTypeTabName.PROCESS ? 'show active' : ''}`}
            id="Process"
            role="tabpanel"                
          >
            {this.getProcessComponentView()}
          </div>
        </div>       
      </section>
    );
  }
}

export default ViewTabsComponent;
