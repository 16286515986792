export enum ActionsTabTypes {
    WELLHEADS = 'wellheads',
    WELLLOGS = 'welllogs',    
    WELLPATHS = 'wellpaths',
    WELLMARKERS = 'wellmarkers',
    WELLMARKERSEXPORT = 'wellmarkersexport',
    WELLMARKERSREMOVE = 'wellmarkersremove',
    WELLSREMOVE = "WellsRemove",
    DELETECORRELATIONS = 'deletePairwiseMultiwellCorrelations',
    WELLSETS = 'wellsets',
    TIMEMARKERS = 'timemarkers',
    CULTURALDATA = 'culturaldata',
    EXPORTDATA = 'exportdata',
    REMOVEDATA = 'removedata',
    AUTOMATED_CORRELATION = 'Automated correlation'
}