import React, { Component } from 'react';
import ToastHelper from '../../../utils/helpers/ToastHelper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';

import { EventNames } from '../../../utils/enums/EventNames';
import { getDlisMetadata, loadDlisFiles, uploadWellLogs, wellMetadata } from '../../../services/wellService';
import { PulseLoader } from "react-spinners";
import FixWellInfosTable, { updateTable } from '../../FixWellInfosTable/FixWellInfosTable';
// import { StandartColors } from '../../../utils/enums/StandartColors';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';
import LoadFileComponent from '../../LoadFileComponent/LoadFileComponent';
import UploadManagement from '../../UploadManagement/UploadManagement';
import { Host_Well } from '../../../Config/Host';
import DlisMetaparametersViwer from '../../DlisMetaparametersViewer/DlisMetaparametersViewer';

interface WellLogsProps{
  fileList: any[];
}

interface WellLogsComponentState {
    fileList: any[];
    uploading: boolean;
    newWellNames:any;
    activeTab:string;
    newWellsNames: any;
    dataList: any[];
    dlisList: string[];
    uploadedDlis:string[];
    uploadDlisDialog: boolean;
    dlisMetadata:any;
    selectedFrames:any[];
    loadingDlis:boolean;
}

const CONTEXT = 'wellLogs';

enum TabNames{
  uploadFile = "Load File",
  selectData = "Select Data",
  correctWellInfo = "Correct Well Info"
}

const tabOrder: string[] =[
  TabNames.uploadFile,
  TabNames.selectData,
  TabNames.correctWellInfo,
]

export default class WellLogsComponent extends Component<{}, WellLogsComponentState> {
  addFiles: string = EventNames.CsvUploaderComponentUpdateListEvent;
  changeWellsNamesEvent = EventNames.updatedWellNamesEvent;
  loadFileComponentEvent: string = EventNames.LoadFileComponentEvent;
  dlisFiles:any[];
  uploadManagement:UploadManagement

  constructor(props: any) {
      super(props);
      this.dlisFiles = []
      this.state = {
        activeTab: TabNames.uploadFile,
        fileList: [],
        uploading: false,
        newWellNames:[],
        dataList:[],
        newWellsNames:{},
        dlisList:[],
        uploadedDlis:[],
        uploadDlisDialog:false,
        dlisMetadata:{},
        selectedFrames:[],
        loadingDlis:false
      };
      this.uploadManagement = new UploadManagement();
  }

  componentDidMount() {  
    document.addEventListener(this.loadFileComponentEvent, this.setFiles);
    document.addEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
  }
  
  componentWillUnmount() {
    document.removeEventListener(this.loadFileComponentEvent, this.setFiles);
    document.removeEventListener(this.changeWellsNamesEvent, this.fixWellsNames);
  }

  fixWellsNames =(event:any) => {
    if (event.type !== this.changeWellsNamesEvent && event.detail.context != CONTEXT){
      return;
    }
    let dataset = event.detail;
    let prevState = this.state.newWellsNames;
    prevState = this.processNewWellsNames(dataset.wellInfosContents);
    this.setState({newWellsNames:prevState});
  }

  setFrames = (frames:any[]) =>{
    let dataList = this.state.dataList;
    const wellInfo = frames.map((frame)=>{
      return [frame.fileName, frame.frameName, frame.frameName];
    })
    const filesNames = frames.map((frame)=>{
      return frame.fileName;
    })
    dataList = dataList.filter((file)=>!filesNames.includes(file[0]));
    dataList = dataList.concat(wellInfo);
    this.setState({selectedFrames:frames,dataList:dataList},()=>{
      updateTable(dataList,CONTEXT)
    });
    
  }

  processNewWellsNames (dataset:any){
    let newWellsNames: any[] = []
    dataset.forEach((row:string[],rowIdx:number)=>{
      newWellsNames.push({"oldName": row[1], "newName": row[2]});
    })
    return newWellsNames;
  }


  setFiles = (event:any) => {
    if (event.type !== this.loadFileComponentEvent && event.detail.context != CONTEXT){
      return;
    }

    const dataset = event.detail;
    let file_list = dataset.fileList;
    const dlis_files:any[] = file_list.filter( (file:any) =>file.name.split(".").at(-1).toLowerCase() === "dlis")
    const las_files = file_list.filter( (file:any) =>file.name.split(".").at(-1).toLowerCase() === "las")
    let wells_infos = las_files.map((file:any,fileIdx:number)=>{
      return [file.name, file.name.split(".")[0], file.name.split(".")[0]]
    })

    const dlisNames = dlis_files.map((file)=>{return file.name});

    updateTable(wells_infos,CONTEXT);    
    this.setState({fileList:dataset.fileList, dlisList:dlisNames, dataList:wells_infos},()=>{
      if(dlis_files.length > 0) {
        this.uploadManagement.clear();
        dlis_files.forEach((file)=>{
          this.uploadManagement.addFile([file],`${Host_Well}/well/upload/dlis`,"POST");
        })
        this.setState({uploadDlisDialog:dlisNames.length>0})
        // this.uploadManagement.start()
      }
    });
  }
 


 /**
   * Upload data
   */
 upload = () =>{

  if(this.state.fileList.length == 0){
    ToastHelper.warning("There is no information to load, please add files in 'Load File' tab and try again.");
    return;
  }
  const las_list = this.state.dataList.filter((line)=>line[0].split(".").at(-1).toLowerCase() === "las");
  const dlisList = this.state.dataList.filter((line)=>line[0].split(".").at(-1).toLowerCase() === "dlis");
  const new_well_names = this.processNewWellsNames(las_list);

  
  if(!this.state.uploading){
    this.setState({uploading:true},()=>{
      var files = this.state.fileList;
      var data = new FormData();
      var wellNames = [];

      for(var idx = 0; idx<files.length; idx++){
        if(files[idx].name.split(".").at(-1).toLowerCase() === "las"){
          data.append("file", files[idx]);
          wellNames.push(files[idx].name.split(".")[0])
        }
      }
      data.append("data",JSON.stringify({newWellNames:new_well_names}))
      if(las_list.length>0){
        this.runUploadWellLogs(data);
      }
      else{
        this.setState({uploading:false})
      }
      
      this.runLoadDlisFiles(dlisList)
    });
  }
 }

 runLoadDlisFiles = (dlisList:any[]) => {
  let dataset:any = {};
  this.setState({loadingDlis:true});
  dlisList.forEach((line)=>{
    if(!Object.keys(dataset).includes(line[0])){
      dataset[line[0]] = [];
    }
    dataset[line[0]].push({
      old:line[1],
      new:line[2]
    });
  });
  if(dlisList.length > 0){
    loadDlisFiles(dataset).then((response)=>{
      if(response.errors.length > 0){
        ToastHelper.error("Error when upload well las on files: " + response.errors, ()=>{
          console.log(response.errors);
        });
      }
      this.setState({loadingDlis:false});
      refreshDataTab();
    }).catch((response)=>{
      this.setState({loadingDlis:false});
      refreshDataTab();
    });
  }
  else{
    this.setState({loadingDlis:false});
  }
 }

   runUploadWellLogs = (data:FormData) => {
    const uploading = uploadWellLogs(data);
    uploading.then((response:any) =>{      
      this.setState({uploading:false})
      if(response.response.errors.length > 0){
        var files_error = response.response.errors.join(", ");
        ToastHelper.error("Error when upload well las on files: " + files_error, ()=>{
          console.log(files_error);
        });
      }
      if (response.response.success.length > 0){
        var files_success = response.response.success.join(", ");
        ToastHelper.success("Upload well logs was successful! " + files_success);
      }
      refreshDataTab();
    }).catch((response:any)=>{
      ToastHelper.error("Error when upload well las","One or many file contains errors!");
      this.setState({uploading:false});
      refreshDataTab();
    })
  }

setTab = (tabName:string) => {
  this.setState({activeTab:tabName});
}

tabHeader = () =>{
  return (
    <div className="process-nav">{tabOrder.map((currentTab,idx)=>{
      return (
        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
          {currentTab}
        </div>
      )
    })}
    </div>
  )
}

dlisDialog = () =>{
  return (
    <>
      <Dialog
          open={this.state.uploadDlisDialog}
          onClose={()=>{ this.setState({uploadDlisDialog:false}) }}
          PaperProps={{
          component:"form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
              event.preventDefault();
              this.setState({uploadDlisDialog:false, dlisList:[], uploadedDlis:this.state.uploadedDlis.concat(this.state.dlisList)}, ()=>{
                this.uploadManagement.start(()=>{
                  getDlisMetadata().then((response)=>{
                    let metadata:any = {}
                    Object.keys(response.metadata).forEach((fileName)=>{
                      if(this.state.uploadedDlis.includes(fileName)){
                        metadata[fileName] = response.metadata[fileName]
                      }
                    })
                    this.setState({dlisMetadata:metadata})
                    this.uploadManagement.clear()
                  })
                })
              })
            }
          }}
      >
          <DialogTitle>Upload Dlis files?</DialogTitle>
          <DialogContent>
          <DialogContentText>The dlis files may take a while due to their size, would you like to send them anyway?</DialogContentText>
          { this.state.dlisList.map((dlisName=>{
                  return <DialogContentText>{dlisName}</DialogContentText>
          }))}
          </DialogContent>
          <DialogActions>
          <Button onClick={()=>{
            this.setState({uploadDlisDialog:false, dlisList:[]});
            this.uploadManagement.clear()
          }}>No</Button>
          <Button type='submit'>Yes, send it!</Button>
          </DialogActions>
      </Dialog>
    </>
  )
}


showCorrectWellInfoTabContent = () =>{
  if(this.state.dataList.length > 0){
    return (
      <>
        <div className='associateLabel'>
          <h3>Correct information read in file:</h3>                     
        </div>
        <div className='table-container styled-scrollbars'>
          <FixWellInfosTable wellsData={this.state.dataList} context={CONTEXT} />
        </div>
      </>
    )
  }
  return <div className='correctWellInfoAlert'>Please load Well Log file(s).</div>
}

tabsView = () =>{

  return (
    <>
      {this.dlisDialog()}
      <section id="tabs">
        <div>          
          {this.tabHeader()}
        </div> 
        
          <div className="tab-content borderedDiv">
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.uploadFile ? 'active' : ''}`}          
              role="tabpanel"
              style={{textAlign:"center"}}    
            >
              <LoadFileComponent context={CONTEXT}/>
            </div>
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.selectData ? 'active' : ''}`}          
              role="tabpanel"          
            >
              { this.state.dlisMetadata && (<DlisMetaparametersViwer dlisMetadata={this.state.dlisMetadata} onChange={this.setFrames}/>)}
              { !this.state.dlisMetadata && (<b>Nothing to show</b>)}
            </div>
            <div
              className={`tab-pane fade show ${this.state.activeTab === TabNames.correctWellInfo ? 'active' : ''}`}          
              role="tabpanel"          
            >
              
                {this.showCorrectWellInfoTabContent()}            
              
            </div>
          </div>
      </section>
    </>
  )
}

tabsNavButton = () =>{
  let activeTabIdx = tabOrder.indexOf(this.state.activeTab);
  return (
      <div className="tabsNavButton">
          { tabOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabOrder[activeTabIdx - 1])}}>
              back
          </button>}
          { tabOrder.indexOf(this.state.activeTab) < tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabOrder[activeTabIdx + 1])}}>
              next
          </button>}
          { tabOrder.indexOf(this.state.activeTab) ==  tabOrder.length -1 && this.state.dataList.length > 0 && <button className='nav-btn btn-next' onClick={()=>{this.upload()}}>
                upload
            </button>}
      </div>
  )
}

  render() {  
    if(this.state.uploading || this.state.loadingDlis){
      return (
      <>
      <h2>Uploading Well Logs</h2>
      <PulseLoader size={20} />
      </>
      )
    }
    else{
      return (
        <>
        <h1>Import Well Logs</h1>
        {this.tabsView()}
        {this.tabsNavButton()}
        </>
      );
    }
  }
}

