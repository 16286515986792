import React, { Component } from 'react';
import SignIn from '../../components/Login/SignIn';

interface LoginPageState {
  
}

class LoginPage extends Component<{}, LoginPageState> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount(): void {
    // window.addEventListener("beforeunload", function (e) {
    //   e.preventDefault();
    //   // Colocar aqui o evento de saída do usuário
    //   console.log('O evento beforeunload foi disparado.');
    //   // e.returnValue = 'Help Leon!!';
    //   // return "Help Leon!"
    // });
  }

  render() {

    return (
      <div style={{marginTop: '-30vh', marginLeft:'20vw',  marginRight:'20vw'}}>
        <SignIn />
      </div>      
    );
  }
}

export default LoginPage;