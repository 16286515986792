/* eslint-disable */

//External packages
import { RestApiService } from "./RestApiService";
import { getProjectToken } from '../utils/helpers/TokenHelper';
import '../utils/helpers/AxiosInterceptor';

//Internal packages
import { Host_Correlation } from "../Config/Host";

/**
 * Process pairwise correlation.
 * @param {*} params Pairwise dataset.
 * @returns Pairwise response.
 */
export async function processPairwise(params) {
    return new Promise((resolve, reject) => {
      var token = getProjectToken();
      var endpoint = `${Host_Correlation}/correlations/pairwise`;
      RestApiService.post(endpoint, params, token)
      .then(response => {      
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
}

/**
 * Get correlation pairs.
 * @param {*} params Object with wells names list.
 * @returns 
 */
export async function getCorrelationPairs(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/correlations_pairs`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Delete pairwise correlations.
 * @param {*} params Pairwise dataset.
 * @returns Pairwise response.
 */
export async function deletePairwise(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/pairwise`;
    RestApiService.delete(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function savePaiwise(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/pairwise/save/`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Process Multiwell Correlation.
 * @param {*} params Multiwell dataset.
 * @returns Multiwell dataset.
 */
export async function processMultiwell(params) {
    return new Promise((resolve, reject) => {
      var token = getProjectToken();
      var endpoint = `${Host_Correlation}/correlations/multiwell`;
      RestApiService.post(endpoint, params, token)
      .then(response => {      
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
}

/**
 * Delete Multiwells Correlations.
 * @param {*} params Multiwell dataset.
 * @returns Multiwell dataset.
 */
export async function deleteMultiwells(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell`;
    RestApiService.delete(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Get Multiwell Correlations data.
 * @returns Multiwell dataset.
 */
export async function getMultiwells() {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Get Pairwise Correlation data.
 * @param pairwiseName Pairwise name.
 * @returns Pairwise dataset.
 */
export async function getPairwise(pairwiseName) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/pairwise/plots/${pairwiseName}`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Retrieve Multiwell Plot Data
 * @param {*} params 
 * @param multiwellName String with multiwell name
 * @returns 
 */
export async function getMultiwellPlot(params, multiwellName) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell/plots/pre_process/${multiwellName}`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Preview New Marker.
 * @param {*} params Correlation preview dataset.
 * @param correlationName Multiwell correlation name.
 * @returns 
 */
export async function previewNewMarker(params, correlationName) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell/plots/${correlationName}/view_new_marker`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * Save New Marker.
 * @param {*} params Correlation new marker dataset.
 * @param correlationName Multiwell correlation name.
 * @returns 
 */
export async function saveNewRGTMarker(params, correlationName) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell/save/${correlationName}/marker`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

/**
 * View multiwell plots.
 * @param correlationName Multiwell correlation name.
 * @returns 
 */
export async function viewMultiwellPlots(correlationName) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell/plots/${correlationName}`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function saveMultiwell(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/multiwell/save`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function getHostInfo() {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/host_infos`;
    RestApiService.get(endpoint, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}

export async function checkWellsInCorrelations(params) {
  return new Promise((resolve, reject) => {
    var token = getProjectToken();
    var endpoint = `${Host_Correlation}/correlations/correlations_by_well_names`;
    RestApiService.post(endpoint, params, token)
    .then(response => {      
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
}