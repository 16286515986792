import React, { Component } from 'react';
import SelectItemsListComponent from '../../SelectItemsList/SelectItemsList';
import { deleteWells, wellMetadata } from '../../../services/wellService';
import { downloadMarkers, getMarkersData } from '../../../services/stratigraphicService';
import { EventNames } from '../../../utils/enums/EventNames';
import ToastHelper from '../../../utils/helpers/ToastHelper';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { checkWellsInCorrelations } from '../../../services/correlationService';
import { refreshDataTab } from '../DataTabComponent/DataTabComponent';

interface WellRemoveComponentState {    
    activeTab:string;
    wellsMetadata: any[];
    wellsNames: string[];
    wellsProperties: string[];
    wellsMarkers: string[];
    selectedWells: string[],
    selectedMarkers: string[],
    showRemoveDialog:boolean,
    correlationsNames:string[],
    showReferenceDialog:boolean,
}

const CONTEXT = 'deleteWells';

enum TabNames{
    selectWells = "Select Wells"
}

const tabOrder: string[] =[
    TabNames.selectWells
]

export default class WellRemoveComponent extends Component<{}, WellRemoveComponentState> {
    SelectItemsListEvent: string = EventNames.SelectItemsListEvent;
    refreshSelectItemsList: string = EventNames.refreshSelectItemsList

    constructor(props: any) {
        super(props);        
        this.state = {
            activeTab: TabNames.selectWells,
            wellsMetadata: [],
            wellsNames: [],
            wellsProperties: [],
            wellsMarkers: [],
            selectedWells: [],
            selectedMarkers: [],
            showRemoveDialog: false,
            correlationsNames:[],
            showReferenceDialog: false,
        };
                
    }

    componentDidMount(){
        this.loadWellsData();
        document.addEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    componentWillUnmount(){
        document.removeEventListener(this.SelectItemsListEvent, this.receiveSelectedItems);
    }

    receiveSelectedItems = (event:any) => {
        if (event.type !== this.SelectItemsListEvent && event.detail.context != CONTEXT ){
          return;
        }
    
        const dataset = event.detail;
        
        this.setState({selectedWells: dataset.items})
              
    }
   
    loadWellsData = ()=>{
        wellMetadata().then((result)=>{          
          this.setState({wellsMetadata:result}, ()=>{
            this.loadWellsNames();
        });
        }).catch((error) => {
          console.error(error);
        });
    }

    setTab = (tabName:string) => {
        this.setState({activeTab:tabName});
    }

    tabHeader = () =>{
        return (
            <div className="process-nav">
                {tabOrder.map((currentTab,idx)=>{
                    return (
                        <div className={`col k-tab ${currentTab==this.state.activeTab?"activeTab":""}`} onClick={()=>{this.setTab(currentTab)}}>
                            {currentTab}
                        </div>
                    )
                })}
            </div>
        )
    }

    removeProcess = ()=>{
        this.checkWellsReferences(this.state.selectedWells)

    }
    checkWellsReferences = (wellsNames:string[]) =>{
        const wells_references = checkWellsInCorrelations({wells_names:wellsNames}).then((response=>{
            if(response.length){
                const correlationsNames: string[] = response.map((responseData:any)=>{
                    return responseData.model_name
                })
                this.setState({showReferenceDialog:true, correlationsNames:correlationsNames})
            }
            else{
                this.setState({showRemoveDialog:true})
            }
        }))
    }

    tabsNavButton = () =>{
        let activeTabIdx = tabOrder.indexOf(this.state.activeTab);
        return (
            <div className="tabsNavButton">
                {/* { tabOrder.indexOf(this.state.activeTab) != 0 && <button className='nav-btn' onClick={()=>{this.setTab(tabOrder[activeTabIdx - 1])}}>
                    back
                </button>}
                { tabOrder.indexOf(this.state.activeTab) < tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.setTab(tabOrder[activeTabIdx + 1])}}>
                    next
                </button>} */}

                { tabOrder.indexOf(this.state.activeTab) == tabOrder.length - 1 && <button className='nav-btn btn-next' onClick={()=>{this.removeProcess()}}>
                    Remove
                </button>}
            </div>
        )
    }

    loadWellsNames = ()=>{
        const wellsNames:string[] = Object.keys(this.state.wellsMetadata)

        this.setState({wellsNames:wellsNames})
    }

    loadWellsProperties = ()=>{
        let wellsPropertiesList: string[] = []
        Object.keys(this.state.wellsMetadata)?.map((well:any) =>{
            Object.keys(this.state.wellsMetadata[well].curves?.versions[0].curves_info).map((prop:any)=>{
                if(!wellsPropertiesList.includes(prop)){
                    wellsPropertiesList.push(prop)
                }
            })
        })

        this.setState({wellsProperties:wellsPropertiesList})
    }
  
    tabsView = () =>{
        return (
          <section id="tabs">
            <div>          
              {this.tabHeader()}
            </div> 
            <div className="tab-content">
                <div
                    className={`tab-pane fade show ${this.state.activeTab === TabNames.selectWells ? 'active' : ''}`}
                    role="tabpanel"
                    style={{textAlign:"center"}}
                >
                    <SelectItemsListComponent listItems={this.state.wellsNames} title='Wells' context={CONTEXT}/>
                </div>
            </div>
            {this.tabsNavButton()}
          </section>
        )
    }
    
    wellsReferencesDialog = () =>{
        return (
            <Dialog
            open={this.state.showReferenceDialog}
            onClose={()=>{ this.setState({showReferenceDialog:false}) }}
            PaperProps={{
              component:"form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                event.preventDefault();
                this.setState({showReferenceDialog:false, showRemoveDialog:true})
              }
            }}
          >
            <DialogTitle>The selected wells are in these correlations, removing them will result in the correlations being removed, do you wish to proceed?</DialogTitle>
            <DialogContent>
                { this.state.correlationsNames.map((correlationName)=>{
                    return (<DialogContentText>{correlationName}</DialogContentText>)
                }) }
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({showReferenceDialog:false})}}>No, cancel</Button>
              <Button type='submit'>Remove</Button>
            </DialogActions>
          </Dialog>
        )
    }

    remove = () => {
        deleteWells({wellsnames:this.state.selectedWells},(status:string)=>{
            ToastHelper.success("The selected wells have been successfully deleted!");
            const customEvent = new CustomEvent(this.refreshSelectItemsList, { detail: {}  });
            document.dispatchEvent(customEvent); 
            this.setState({wellsNames:[], selectedWells:[]},()=>{
                refreshDataTab();
                this.loadWellsData()
            })
        },()=>{
            ToastHelper.error("Error when removing wells!");
        })
    }

    removeDialog = () =>{
        return (
            <Dialog
            open={this.state.showRemoveDialog}
            onClose={()=>{ this.setState({showRemoveDialog:false}) }}
            PaperProps={{
              component:"form",
              onSubmit: (event: React.FormEvent<HTMLFormElement>) =>{
                event.preventDefault();
                this.setState({showRemoveDialog:false}, ()=>{
                  this.remove()
                })
              }
            }}
          >
            <DialogTitle>Are you sure about removing these items?</DialogTitle>
            <DialogContent>
                { this.state.selectedWells.map((wellName)=>{
                    return (<DialogContentText>{wellName}</DialogContentText>)
                }) }
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({showRemoveDialog:false})}}>No, cancel</Button>
              <Button type='submit'>Remove</Button>
            </DialogActions>
          </Dialog>
        )
    }
    
    render(): React.ReactNode {
        return (
            <>
                <h1>Remove Wells</h1>
                {this.tabsView()}
                {this.wellsReferencesDialog()}
                {this.removeDialog()}
            </>
        )
    }
}